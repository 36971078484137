@import '../../styles/settings';

.article {
  width: 310px;
  margin: 15px 30px;
}

.article .article-background {
  position: relative;
  border-radius: 8px;
  box-shadow:
          -12px -12px 12px 0 $neu-upper,
          12px 12px 12px 0 $neu-lower;
  margin-bottom: 1em;
  overflow: hidden;
  height: 300px;
  width: auto;
  z-index: 1;
}

.article .image {
  max-height: 200px;
  padding: 10px;
  width: 290px;
}

.article a {
  color: $text-color;
  font-size: 18px;
}

.article .title-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3;
}

.article .source {
  box-shadow: none;
  left: -25px;
  position: absolute;
  top: -15px;
  width: 50px;
  z-index: 3;
}

.article .title {
  padding: 0 1em;
  text-align: center;
}
