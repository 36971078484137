@import '../../styles/settings';

.header {
    font-size: 18px;
    margin-bottom: 20px;
    padding: 20px 0;
}

.header .active {
    background: $highlight-gray;
    font-weight: bold;
}

.header .item {
    color: $text-color;
    padding: 20px;
    text-decoration: none;
}
